/*
* CSS Variables
*/

/* Non-Theme Vars */
:root {
  /* Fonts */
  --font-family: Inter, sans-serif;

  --font-heading: normal 500 28px/36px var(--font-family);
  --font-heading-bold: normal 700 28px/36px var(--font-family);
  /* Lol, soz b-dog */
  --font-heading-large-bold: normal 700 32px/40px var(--font-family);

  --font-text: normal 500 14px/22px var(--font-family);
  --font-text-bold: normal 600 14px/22px var(--font-family);

  --font-text-user: normal 500 16px/22px var(--font-family);

  --font-text-small: normal 500 12px/16px var(--font-family);
  --font-text-small-bold: normal 600 12px/16px var(--font-family);


  --font-text-medium: normal 500 16px/22px var(--font-family);
  --font-text-medium-bold: normal 600 16px/22px var(--font-family);

  --font-text-large: normal 500 18px/26px var(--font-family);
  --font-text-large-bold: normal 600 18px/26px var(--font-family);

  --font-text-x-large: normal 500 20px/28px var(--font-family);
  --font-text-x-large-bold: normal 600 20px/28px var(--font-family);

  /* Borders */
  --border-radius-small: 4px;
  --border-radius-medium: 8px;
  --border-radius-large: 16px;

  /*
  * Misc
  */
  --pane-gap: 8px;
  --snappy-bezier: cubic-bezier(0.16, 1, 0.3, 1);
}

/* Light Mode Colors */
:root[data-theme="light"] {
  --color-rgb: 0, 29, 55;
  --color-rgb-brand: 0, 134, 255;
  --color-rgb-brand-alt: 219, 140, 75;
  --color-rgb-danger: 219, 75, 75;
  --color-rgb-inverse: 255, 255, 255;
  --color-rgb-background: 255, 255, 255;
  --color-rgb-background-alt: 237, 240, 242;

  --color-text-heading: rgb(var(--color-rgb));
  --color-text-heading-primary: rgb(35, 112, 180); /* linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), rgba(var(--color-rgb-brand)); */
  --color-text: rgba(var(--color-rgb), 0.9);
  --color-text-secondary: rgba(var(--color-rgb), 0.65);
  --color-text-secondary-primary: rgba(var(--color-rgb-brand), 0.8);
  --color-text-secondary-inverse: rgba(var(--color-rgb-inverse), 0.85);
  --color-text-secondary-danger: rgba(var(--color-rgb-danger), 0.8);
  --color-text-tertiary: rgba(var(--color-rgb), 0.5);
  --color-text-tertiary-inverse: rgba(var(--color-rgb-inverse), 0.65);
  --color-text-placeholder: rgba(var(--color-rgb), 0.35);
  --color-text-inverse: rgb(var(--color-rgb-inverse));
  --color-text-primary: rgb(var(--color-rgb-brand));
  --color-text-primary-alt: rgb(var(--color-rgb-brand-alt));
  --color-text-danger: rgb(var(--color-rgb-danger));
  --color-text-on-white: var(--color-text);

  --color-background: rgb(var(--color-rgb-background));
  --color-background-secondary: #F9FAFC;
  --color-background-tertiary: #F5F6FA;
  --color-background-quartenary: rgba(var(--color-rgb-background-alt), 0.25);

  --color-invisible: transparent;

  --color-document-gray: #F0EBEB;
  --color-document-gray-2: #F4F1F1;
  
  --color-border: rgba(216, 223, 229, 1);
  --color-border-secondary: #e8ebed;
  --color-border-tertiary: #EEF0F2;
  --color-border-inverse: rgba(var(--color-rgb-inverse), 0.35);
  --color-border-glass: rgba(255, 255, 255, 0.3);
  
  --color-primary: rgb(var(--color-rgb-brand));
  --color-primary-hover: linear-gradient(0deg,rgba(0, 0, 0, 0.08),rgba(0, 0, 0, 0.08)),
      rgba(var(--color-rgb-brand), 1);
  --color-primary-active: linear-gradient(0deg,rgba(0, 0, 0, 0.16),rgba(35, 23, 23, 0.16)),
      rgba(var(--color-rgb-brand), 1);
  
  --color-primary-alt: rgb(var(--color-rgb-brand-alt));
  --color-primary-alt-hover: linear-gradient(0deg,rgba(0, 0, 0, 0.08),rgba(0, 0, 0, 0.08)),
      rgba(var(--color-rgb-brand-alt), 1);
  --color-primary-alt-active: linear-gradient(0deg,rgba(0, 0, 0, 0.16),rgba(0, 0, 0, 0.16)),
      rgba(var(--color-rgb-brand-alt), 1);
  
  --color-danger: rgb(var(--color-rgb-danger));
  --color-danger-hover: linear-gradient(0deg,rgba(0, 0, 0, 0.08),rgba(0, 0, 0, 0.08)),
      rgba(var(--color-rgb-danger), 1);
  --color-danger-active: linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)),
    rgba(var(--color-rgb-danger), 1);
  
  --color-ai: #8a2be2;
  --color-ai-hover: linear-gradient(0deg,rgba(0, 0, 0, 0.08),rgba(0, 0, 0, 0.08)),
      var(--color-ai);
  --color-ai-active: linear-gradient(0deg,rgba(0, 0, 0, 0.16),rgba(0, 0, 0, 0.16)),
      var(--color-ai);

  --color-subtle: var(--color-invisible);
  --color-subtle-hover: rgba(63, 89, 112, 0.08);
  --color-subtle-active: rgba(63, 89, 112, 0.12);
  --color-subtle-active-dark: rgba(63, 89, 112, 0.16);
  --color-subtle-active-darker: rgba(63, 89, 112, 0.2);
  --color-subtle-primary: rgba(var(--color-rgb-brand), 0.08);
  --color-subtle-primary-active: rgba(var(--color-rgb-brand), 0.16);
  --color-subtle-primary-hover: rgba(var(--color-rgb-brand), 0.1);
  --color-subtle-primary-alt: rgba(var(--color-rgb-brand-alt), 0.1);
  --color-subtle-primary-alt-active: rgba(var(--color-rgb-brand-alt), 0.16);
  --color-subtle-primary-alt-hover: rgba(var(--color-rgb-brand-alt), 0.22);
  --color-subtle-danger: rgba(var(--color-rgb-danger), 0.1);
  --color-subtle-danger-active: rgba(var(--color-rgb-danger), 0.16);
  --color-subtle-danger-hover: rgba(var(--color-rgb-danger), 0.22);

  --color-icon: rgba(var(--color-rgb), 0.65);
  --color-icon-strong: rgba(var(--color-rgb), 0.9);
  --color-icon-primary: rgba(var(--color-rgb-brand), 0.8);
  --color-icon-primary-alt: rgba(var(--color-rgb-brand-alt), 0.8);
  --color-icon-danger: rgba(var(--color-rgb-danger), 0.8);
  --color-icon-inverse: rgba(var(--color-rgb-inverse), 0.8);

  --color-user-blue: #2C73D2;
  --color-user-blue-2: #538DEF;
  --color-user-blue-3:#74A8FF;
  --color-user-blue-4: #93C3FF;
  --color-user-blue-5: #B2E0FF;

  --color-user-turquoise: #007459;
  --color-user-turquoise-2: #329275;
  --color-user-turquoise-3: #53B092;
  --color-user-turquoise-4: #73D0B0;
  --color-user-turquoise-5: #93F1D0;

  --color-user-green: #388E3C;
  --color-user-green-2: #48984C;
  --color-user-green-3: #4CAF50;
  --color-user-green-4: #81C784;
  --color-user-green-5: #C8E6C9;

  --color-user-orange: #FF6F00;
  --color-user-orange-2: #FFA000;
  --color-user-orange-3: #FFC107;
  --color-user-orange-4: #FFD54F;
  --color-user-orange-5: #FFD54F;

  --color-user-pink: #880E4F;
  --color-user-pink-2: #B03C71;
  --color-user-pink-3: #D96295;
  --color-user-pink-4: #FF89BB;
  --color-user-pink-5: #FFB0E2;

  --color-user-yellow: #D29D1A;
  --color-user-yellow-2: #FBC02D;
  --color-user-yellow-3: #FFEB3B;
  --color-user-yellow-4: #FFF176;
  --color-user-yellow-5: #FFF9C4;
  
  --color-user-purple: #845EC2;
  --color-user-purple-2: #A178DF;
  --color-user-purple-3: #BE93FD;
  --color-user-purple-4: #DCB0FF; 
  --color-user-purple-5: #FACCFF;

  --color-user-red: #B71C1C;
  --color-user-red-2: #DD4437;
  --color-user-red-3: #FF6654;
  --color-user-red-4: #FF8872;
  --color-user-red-5: #FFAB91;

  --color-user-brown: #5D4037;
  --color-user-brown-2: #816157;
  --color-user-brown-3: #A6847A;
  --color-user-brown-4: #CDA99E;
  --color-user-brown-5: #F4CFC4;

  --color-user-gray: #505254;
  --color-user-gray-2: #767779;
  --color-user-gray-3: #9B9D9F;
  --color-user-gray-4: #AEAFB1;
  --color-user-gray-5: #D3D5D7;

  /* Shadows */
  --color-shadow: #001d37;

  /* Shadows From Awesome Tool https: //www.joshwcomeau.com/shadow-palette/ */
  --shadow-color: 228.12deg 20.64% 83.14%;
  --shadow-elevation-none:
    0px 0px 0px hsl(var(--shadow-color) / 0.16),
    0px 0px 0px -0px hsl(var(--shadow-color) / 0.17),
    0px 0px 0px -0px hsl(var(--shadow-color) / 0.17);
  --shadow-elevation-low:
    0.3px 0.6px 0.8px hsl(var(--shadow-color) / 0.16),
    0.4px 0.9px 1.1px -1.2px hsl(var(--shadow-color) / 0.17),
    0.8px 1.8px 2.2px -2.4px hsl(var(--shadow-color) / 0.17);
  --shadow-elevation-mid:
    0.3px 0.6px 0.8px hsl(var(--shadow-color) / 0.17),
    0.7px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.17),
    1.7px 3.8px 4.7px -1.6px hsl(var(--shadow-color) / 0.18),
    3.9px 9.1px 11.2px -2.4px hsl(var(--shadow-color) / 0.18);
  --shadow-elevation-high:
    0.3px 0.6px 0.8px hsl(var(--shadow-color) / 0.18),
    1px 2.3px 2.8px -0.4px hsl(var(--shadow-color) / 0.18),
    1.8px 4.2px 5.2px -0.8px hsl(var(--shadow-color) / 0.18);

  --shadow-popover: 0px 4px 32px rgba(var(--color-rgb), 0.1),
    0px 4px 16px rgba(var(--color-rgb), 0.1),
    0px 1px 1px rgba(var(--color-rgb), 0.15);
  --shadow-emphasis: 0px 2px 8px rgba(var(--color-rgb), 0.05),
    0px 1px 1px rgba(var(--color-rgb), 0.15);
  --shadow-control: 0px 0px 4px -1px rgba(var(--color-rgb), 0.15),
    0px 0px 8px rgba(var(--color-rgb), 0.05),
    0px 1px 2px rgba(var(--color-rgb), 0.15);
  --shadow-big: 0px 4px 32px rgba(0, 29, 55, 0.1);
}

/* Dark Mode Colors */
:root[data-theme="dark"] {
  --color-rgb: 255, 255, 255;
  --color-rgb-brand: 66, 153, 255;
  --color-rgb-brand-alt: 255, 159, 89;
  --color-rgb-danger: 255, 99, 99;
  --color-rgb-inverse: 17, 19, 23;
  --color-rgb-background: 17, 19, 23;
  --color-rgb-background-alt: 26, 29, 35;

  --color-text-heading: rgb(var(--color-rgb));
  --color-text-heading-primary: rgb(102, 153, 255);
  --color-text: rgba(var(--color-rgb), 0.9);
  --color-text-secondary: rgba(var(--color-rgb), 0.65);
  --color-text-secondary-primary: rgba(var(--color-rgb-brand), 0.8);
  --color-text-secondary-inverse: rgba(var(--color-rgb-inverse), 0.85);
  --color-text-secondary-danger: rgba(var(--color-rgb-danger), 0.8);
  --color-text-tertiary: rgba(var(--color-rgb), 0.45);
  --color-text-tertiary-inverse: rgba(var(--color-rgb-inverse), 0.65);
  --color-text-placeholder: rgba(var(--color-rgb), 0.35);
  --color-text-inverse: rgb(var(--color-rgb-inverse));
  --color-text-primary: rgb(var(--color-rgb-brand));
  --color-text-primary-alt: rgb(var(--color-rgb-brand-alt));
  --color-text-danger: rgb(var(--color-rgb-danger));
  --color-text-on-white: var(--color-text-inverse);

  --color-background: rgb(var(--color-rgb-background));
  --color-background-secondary: rgb(22, 24, 29);
  --color-background-tertiary: rgb(26, 29, 35);
  --color-background-quartenary: rgba(var(--color-rgb-background-alt),
      0.5);

  --color-invisible: transparent;
  
  --color-document-gray: #262626;
  --color-document-gray-2: #262626;
  
  --color-menu-active: rgba(var(--color-rgb), 0.1);
  --shadow-menu-active: var(--shadow-elevation-mid);

  --color-border: rgba(255, 255, 255, 0.1);
  --color-border-secondary: rgba(255, 255, 255, 0.07);
  --color-border-tertiary: rgba(255, 255, 255, 0.05);
  --color-border-inverse: rgba(var(--color-rgb-inverse), 0.35);
  --color-border-glass: rgba(255, 255, 255, 0.1);

  --color-primary: rgb(var(--color-rgb-brand));
  --color-primary-hover: linear-gradient(0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)),
    rgba(var(--color-rgb-brand), 1);
  --color-primary-active: linear-gradient(0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)),
    rgba(var(--color-rgb-brand), 1);

  --color-primary-alt: rgb(var(--color-rgb-brand-alt));
  --color-primary-alt-hover: linear-gradient(0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)),
    rgba(var(--color-rgb-brand-alt), 1);
  --color-primary-alt-active: linear-gradient(0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)),
    rgba(var(--color-rgb-brand-alt), 1);

  --color-danger: rgb(var(--color-rgb-danger));
  --color-danger-hover: linear-gradient(0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)),
    rgba(var(--color-rgb-danger), 1);
  --color-danger-active: linear-gradient(0deg,
      rgba(255, 255, 255, 0.16),
      rgba(255, 255, 255, 0.16)),
    rgba(var(--color-rgb-danger), 1);

  --color-subtle: var(--color-invisible);
  --color-subtle-hover: rgba(255, 255, 255, 0.05);
  --color-subtle-active: rgba(255, 255, 255, 0.07);
  --color-subtle-active-dark: rgba(255, 255, 255, 0.09);
  --color-subtle-active-darker: rgba(255, 255, 255, 0.11);
  --color-subtle-primary: rgba(var(--color-rgb-brand), 0.15);
  --color-subtle-primary-active: rgba(var(--color-rgb-brand), 0.25);
  --color-subtle-primary-hover: rgba(var(--color-rgb-brand), 0.2);
  --color-subtle-primary-alt: rgba(var(--color-rgb-brand-alt), 0.15);
  --color-subtle-primary-alt-active: rgba(var(--color-rgb-brand-alt),
      0.25);
  --color-subtle-primary-alt-hover: rgba(var(--color-rgb-brand-alt), 0.2);
  --color-subtle-danger: rgba(var(--color-rgb-danger), 0.15);
  --color-subtle-danger-active: rgba(var(--color-rgb-danger), 0.25);
  --color-subtle-danger-hover: rgba(var(--color-rgb-danger), 0.2);

  --color-icon: rgba(var(--color-rgb), 0.65);
  --color-icon-strong: rgba(var(--color-rgb), 0.9);
  --color-icon-primary: rgba(var(--color-rgb-brand), 0.8);
  --color-icon-primary-alt: rgba(var(--color-rgb-brand-alt), 0.8);
  --color-icon-danger: rgba(var(--color-rgb-danger), 0.8);
  --color-icon-inverse: rgba(var(--color-rgb-inverse), 0.8);
  
  --color-user-blue: #538def;
  --color-user-blue-2: #74a8ff;
  --color-user-blue-3: #93c3ff;
  --color-user-blue-4: #b2e0ff;
  --color-user-blue-5: #d1f0ff;

  --color-user-turquoise: #329275;
  --color-user-turquoise-2: #53b092;
  --color-user-turquoise-3: #73d0b0;
  --color-user-turquoise-4: #93f1d0;
  --color-user-turquoise-5: #b3ffe0;

  --color-user-green: #48984c;
  --color-user-green-2: #4caf50;
  --color-user-green-3: #81c784;
  --color-user-green-4: #a5d6a7;
  --color-user-green-5: #c8e6c9;

  --color-user-orange: #ffa000;
  --color-user-orange-2: #ffc107;
  --color-user-orange-3: #ffd54f;
  --color-user-orange-4: #ffe082;
  --color-user-orange-5: #ffecb3;

  --color-user-pink: #d96295;
  --color-user-pink-2: #ff89bb;
  --color-user-pink-3: #ffb0e2;
  --color-user-pink-4: #ffd6f6;
  --color-user-pink-5: #ffebfa;

  --color-user-yellow: #fbc02d;
  --color-user-yellow-2: #ffeb3b;
  --color-user-yellow-3: #fff176;
  --color-user-yellow-4: #fff59d;
  --color-user-yellow-5: #fff9c4;

  --color-user-purple: #a178df;
  --color-user-purple-2: #be93fd;
  --color-user-purple-3: #dcb0ff;
  --color-user-purple-4: #faccff;
  --color-user-purple-5: #fce4ff;

  --color-user-red: #ff5252;
  --color-user-red-2: #ff7373;
  --color-user-red-3: #ff9595;
  --color-user-red-4: #ffb7b7;
  --color-user-red-5: #ffd9d9;

  --color-user-brown: #816157;
  --color-user-brown-2: #a6847a;
  --color-user-brown-3: #cda99e;
  --color-user-brown-4: #f4cfc4;
  --color-user-brown-5: #ffe5dd;

  --color-user-gray: #767779;
  --color-user-gray-2: #9b9d9f;
  --color-user-gray-3: #aeafb1;
  --color-user-gray-4: #d3d5d7;
  --color-user-gray-5: #e8e9ea;

  /* Shadows */
  --color-shadow: white;

  /* Shadows From Awesome Tool https: //www.joshwcomeau.com/shadow-palette/ */
  --shadow-color: 220deg 40% 2%;
  --shadow-elevation-low: 0px 1px 3px hsl(var(--shadow-color) / 0.25),
    0px 3px 8px -1.2px hsl(var(--shadow-color) / 0.25);
  --shadow-elevation-mid: 0px 0.6px 0.8px hsl(var(--shadow-color) / 0.25),
    0px 3px 3.7px -0.8px hsl(var(--shadow-color) / 0.25),
    0px 6px 7.4px -1.6px hsl(var(--shadow-color) / 0.25);
  --shadow-elevation-high: 0px 0.6px 0.8px hsl(var(--shadow-color) / 0.3),
    0px 5.4px 6.7px -0.4px hsl(var(--shadow-color) / 0.3),
    0px 10.8px 13.4px -0.8px hsl(var(--shadow-color) / 0.3),
    0px 21.6px 26.8px -1.2px hsl(var(--shadow-color) / 0.3);
  --shadow-popover: 0px 4px 32px rgba(0, 0, 0, 0.4),
    0px 4px 16px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.15);
  --shadow-emphasis: 0px 2px 8px rgba(0, 0, 0, 0.25),
    0px 1px 1px rgba(0, 0, 0, 0.15);
  --shadow-control: 0px 0px 4px -1px rgba(0, 0, 0, 0.15),
    0px 0px 6px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-big: 0px 4px 32px rgba(0, 0, 0, 0.25);
}

:root {
  --border-secondary: 1px solid var(--color-border-secondary);
  --border-tertiary: 1px solid var(--color-border-tertiary);
  --border-inverse: 1px solid var(--color-border-inverse);
  --border-invisible: 1px solid transparent;
  --border: 1px solid var(--color-border);
}

/*
* react-day-picker variables
*/

:root {
  --rdp-cell-size: 30px;
  --rdp-accent-color: var(--color-subtle-primary);
  --rdp-background-color: var(--color-background);
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: var(--color-text-primary);
  --rdp-background-color-dark: red;
  /* Outline border for focused elements */
  --rdp-outline: none;
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: none;
}

/*
* Default styles
*/

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: var(--font-size);
  color: var(--color-text);
  background: var(--color-background-secondary);
  overscroll-behavior: none;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: default;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
}

strong {
  font-weight: 600;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  padding: 0;
}

:focus-visible {
  outline: none;
}